<template>
  <div class="flex-grow overflow-auto w-full flex flex-col px-1 sm:px-2 pb-1 pt-11 lg:pt-0" :style="{ 'box-shadow': showShadowBorder ? '0px 5px 20px rgba(35, 17, 131, 0.02)' : '' }">
    <div class="flex w-full max-w-[1800px] mx-auto max-md:flex-wrap xs:px-2 lg:px-5 xl:px-10 transition-all ease-in duration-100">
      <div class="w-full md:w-7/12 lg:w-7/12">
        <div class="flex h-10 text-24 items-end font-500 mt-5 pl-6 text-semi-dark-text">{{ $t('dashboard.hello', { name }) }}</div>
        <div>
          <div class="w-full h-25 mt-3 border-stroke border-solid border rounded-20 p-4 flex">
            <div class="flex pl-5 justify-center flex-col w-1/3">
              <span class="font-600 text-[30px]">{{ collections.length }}</span> <span class="font-500">{{ $t('dashboard.collections') }}</span>
            </div>
            <div class="flex pl-5 justify-center flex-col w-1/3">
              <span class="font-600 text-[30px]">{{ totalCollectionQuestionCount }}</span> <span class="font-500">{{ $t('dashboard.total_cards') }}</span>
            </div>
            <div class="flex pl-5 justify-center flex-col w-1/3">
              <span class="font-600 text-[30px]">{{ datasourceCount }}</span> <span class="font-500">{{ $t('dashboard.data_sources') }}</span>
            </div>
          </div>
          <div class="w-full mt-3 border-stroke border-solid border rounded-20 p-4 flex flex-col">
            <div class="flex justify-between items-center">
              <div class="text-18 font-600 py-1 text-semi-dark-text">{{ $t('dashboard.review_collections') }}</div>
              <PillButton tertiary :text="$t('dashboard.see_all_collections')" trailingIcon="chevron_right" @click="$router.push('/collections')" />
            </div>
            <div class="flex pt-4 items-start">
              <div class="rounded-10 w-full shadow-card-sm bg-white bg-opacity-40">
                <div v-if="!collections.length" class="h-350 w-full flex flex-col items-center justify-center">
                  <Icon name="collections" class="w-8 h-8 text-gray-500 flex-shrink-0" />
                  <div class="text-13 my-3 text-gray-600 text-center">
                    {{ $t('dashboard.no_collections') }} <br />
                    {{ $t('dashboard.create_first_collection') }}
                  </div>
                  <PillButton icon="plus" :text="$t('collections.create2')" outlined class="bg-white" @click="$router.push('/collections#new')" />
                </div>
                <div v-else class="">
                  <div class="flex w-full p-4 pb-0">
                    <div class="text-16 font-500 text-semi-dark-text">{{ firstCollection.name }}</div>
                    <div class="ml-auto">{{ $t('dashboard.x_cards', { count: firstCollectionQuestionCount }) }}</div>
                  </div>
                  <div v-if="!firstCollectionQuestionCount" class="h-[366px] w-full flex flex-col items-center justify-center">
                    <Icon name="collections" class="w-8 h-8 text-gray-500 flex-shrink-0" />
                    <div class="text-13 my-3 text-gray-600 text-center">{{ $t('dashboard.no_cards') }}<br />{{ $t('dashboard.add_first_card') }}</div>
                    <PillButton icon="plus" :text="$t('dashboard.add_card')" outlined class="bg-white" @click="onAddCardClick" />
                  </div>
                  <div v-else class="h-[366px] pb-5">
                    <AnalysisCardSwiper v-if="activeCollectionQuestionResult && activeCollectionQuestionResult.result" class="h-300" :result="activeCollectionQuestionResult.result" />
                    <div v-if="activeCollectionQuestionResult && activeCollectionQuestionResult.loading" class="flex-grow flex items-center justify-center w-full h-300">
                      <Spinner class="w-8" />
                    </div>
                    <div v-else-if="activeCollectionQuestionResult && activeCollectionQuestionResult.selectAccount" class="h-300 flex-grow flex flex-col items-center justify-center">
                      <div class="font-500 text-red-700 text-center">{{ activeCollectionQuestionResult.errorMessage }}</div>
                      <div class="pt-3">{{ $t('collections.select_from_below') }}</div>
                      <div class="mt-4 h-10 w-10/12">
                        <v-select v-model="account" :placeholder="$t('datastore.select_account')" :clearable="false" v-bind:selected="!!account" :options="getAccounts" class="w-full">
                          <template v-slot:option="{ account }">
                            <div>{{ account.email }}</div>
                            <div>{{ account.name }}</div>
                          </template>
                        </v-select>
                      </div>
                      <PillButton tertiary :text="$t('save')" class="mt-3" @click="saveAccount"></PillButton>
                    </div>
                    <div
                      v-else-if="activeCollectionQuestionResult.errorMessage === 'Question not found!'"
                      class="h-300 flex-grow flex flex-col items-center justify-center cursor-pointer px-3 text-center"
                    >
                      <div v-if="!activeCollectionQuestionResult.errorMessage">{{ $t('collections.failed_to_load_card') }}</div>
                      <div v-else>{{ activeCollectionQuestionResult.errorMessage }}</div>
                    </div>
                    <div
                      v-else-if="activeCollectionQuestionResult.error"
                      class="h-300 flex-grow flex flex-col items-center justify-center cursor-pointer px-3 text-center"
                      @click="fetchCollectionQuestion"
                    >
                      <icon name="refresh" class="text-black w-6 h-6 mb-2" />
                      <div v-if="!activeCollectionQuestionResult.errorMessage">{{ $t('collections.failed_to_load_card') }}</div>
                      <div v-else>{{ activeCollectionQuestionResult.errorMessage }}</div>
                    </div>
                    <div v-if="firstCollectionQuestionCount" class="px-5">
                      <Divider dense />
                      <div class="flex justify-between items-center mt-4">
                        <div
                          :class="{ 'opacity-25': collectionQuestionIndex === 0 }"
                          class="cursor-pointer w-8 h-8 rounded-full bg-white border border-stroke flex items-center justify-center select-none"
                          @click="prevQuestion"
                        >
                          <Icon name="chevron_left" class="w-5 h-5" />
                        </div>
                        <div>{{ activeCollectionQuestionText }}</div>
                        <div
                          :class="{ 'opacity-25': collectionQuestionIndex + 1 === firstCollectionQuestionCount }"
                          class="cursor-pointer w-8 h-8 rounded-full bg-white border border-stroke flex items-center justify-center select-none"
                          @click="nextQuestion"
                        >
                          <Icon name="chevron_right" class="w-5 h-5" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-5/12 lg:w-5/12 pl-5 pt-[46px]">
        <div class="border-stroke border-solid border rounded-20 mt-6 px-2">
          <div class="flex h-10 text-18 items-end font-500 pl-4 mt-[7px] text-dark-text">{{ $t('dashboard.alerts_events') }}</div>
          <div class="p-4">{{ $t('dashboard.no_notifications') }}</div>
        </div>
        <div class="border-stroke border-solid border rounded-20 p-4 mt-[12px]">
          <div class="flex h-9 text-18 items-center font-500 pl-4 text-dark-text">{{ $t('dashboard.favourite_questions') }}</div>
          <FavouriteQuestions ref="favouriteQuestions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AnalysisCardSwiper from '@/components/conversation/AnalysisCardSwiper.vue';
import FavouriteQuestions from '@/components/dashboard/FavouriteQuestions.vue';
import debounce from '@/helpers/debounce';
import { apiPostCollectionsQuery } from '@/helpers/api';

/* eslint-disable no-template-curly-in-string */

export default {
  name: 'Dashboard',
  components: { FavouriteQuestions, AnalysisCardSwiper },
  data() {
    return {
      collectionQuestionResults: {},
      collectionQuestionIndex: 0,
      showShadowBorder: false,
    };
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.onResize);
    this.resizeObserver.observe(this.$el);
  },
  beforeUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  computed: {
    ...mapState(['user', 'availableDataSources', 'org', 'conversationSuggestions', 'activeDatasourceType', 'collections', 'dataSourcesList']),
    datasourceCount() {
      let activeCount = 0;
      this.dataSourcesList.forEach((item) => {
        Object.values(item).forEach((ds) => {
          if (ds.active) {
            activeCount += Object.keys(ds.accounts)?.length || 0;
          }
        });
      });
      return activeCount;
    },
    totalCollectionQuestionCount() {
      return this.collections.reduce((acc, c) => acc + c.questions.length, 0);
    },
    suggestions() {
      return this.conversationSuggestions?.[this.activeDatasourceType]?.suggestions || [];
    },
    name() {
      return this.user?.name?.split(' ')?.[0] || '';
    },
    firstCollection() {
      return this.collections?.[0] || {};
    },
    firstCollectionQuestionCount() {
      return this.firstCollection?.questions?.length || 0;
    },
    firstCollectionQuestions() {
      return this.firstCollection?.questions || [];
    },
    activeCollectionQuestionText() {
      return this.firstCollectionQuestions?.[this.collectionQuestionIndex]?.phrase || '';
    },
    activeCollectionQuestionId() {
      return this.firstCollectionQuestions?.[this.collectionQuestionIndex]?.id;
    },
    activeCollectionQuestionResult() {
      if (this.activeCollectionQuestionId && this.activeCollectionQuestionId in this.collectionQuestionResults) {
        return this.collectionQuestionResults[this.activeCollectionQuestionId];
      }
      return null;
    },
    activeOrgDatasource() {
      return `${this.org}_${this.activeDatasourceType}`;
    },
  },
  created() {
    this.fetchCollectionQuestion();
  },
  methods: {
    ...mapActions(['query']),
    // eslint-disable-next-line
    onResize: debounce(function (e) {
      const element = e[0].target;
      this.showShadowBorder = element.scrollHeight > element.clientHeight;
    }, 100),
    onAddCardClick() {
      this.$router.push({
        name: 'collection',
        params: {
          id: this.firstCollection.id,
          questionId: 'new',
        },
      });
    },
    prevQuestion() {
      if (this.collectionQuestionIndex === 0) return;
      this.collectionQuestionIndex--;

      this.fetchCollectionQuestion();
    },
    nextQuestion() {
      if (this.collectionQuestionIndex + 1 === this.firstCollectionQuestionCount) return;
      this.collectionQuestionIndex++;

      this.fetchCollectionQuestion();
    },
    async fetchCollectionQuestion() {
      if (!this.firstCollectionQuestionCount) return Promise.resolve();
      const { id, type, datasource_id, account_id } = this.firstCollectionQuestions[this.collectionQuestionIndex];

      if (id in this.collectionQuestionResults && this.collectionQuestionResults[id]?.result) return Promise.resolve();

      this.collectionQuestionResults[id] = {
        loading: true,
        error: false,
        result: null,
        selectAccount: false,
        errorMessage: '',
      };

      try {
        const response = await apiPostCollectionsQuery({
          type,
          datasource_id,
          account_id,
          collection_id: this.firstCollection.id,
          id,
        });
        if (response.status === 200) {
          this.collectionQuestionResults[id].loading = false;

          if (response?.data?.responses?.[0]?.card) {
            this.collectionQuestionResults[id].result = response.data.responses[0].card;
          } else if (response?.data?.responses?.[0]?.text) {
            this.collectionQuestionResults[id].result = { text: response.data.responses[0].text };
          } else {
            this.collectionQuestionResults[id].error = true;
          }
        } else {
          this.collectionQuestionResults[id].loading = false;
          this.collectionQuestionResults[id].error = true;
          this.collectionQuestionResults[id].selectAccount = response.data?.select_account || false;
          this.collectionQuestionResults[id].errorMessage = response.data?.message || '';
        }
      } catch (e) {
        this.collectionQuestionResults[id].loading = false;
        this.collectionQuestionResults[id].error = true;
      }

      return Promise.resolve();
    },
  },
  watch: {
    activeOrgDatasource: {
      handler() {
        if (this.$refs.favouriteQuestions) {
          this.$refs.favouriteQuestions.setFavouriteAccordionIndex(null);
          this.$refs.favouriteQuestions.getTenantFavourites();
        }
      },
      immediate: true,
    },
  },
};
</script>
