import { createRouter, createWebHistory } from 'vue-router';
import Conversation from '@/components/views/Conversation.vue';
import Settings from '@/components/views/user/Settings.vue';
import Security from '@/components/views/user/Security.vue';
import AuthCallback from '@/components/views/AuthCallback';
import Collections from '@/components/views/Collections';
import Dashboard from '@/components/views/Dashboard.vue';
import Discovery from '@/components/views/Discovery.vue';
import Collection from '@/components/views/Collection.vue';
import Datasources from '@/components/views/user/Datasources.vue';
import SideLayout from '@/components/layouts/Side.vue';
import Login from '@/components/views/Login';

let store;

const router = createRouter({
  history: createWebHistory(),
  base: '/',
  routes: [
    {
      path: '/',
      component: Discovery,
      meta: { requiresAuth: true },
      children: [
        {
          path: '/',
          component: Dashboard,
          name: 'dashboard',
          meta: { requiresAuth: true },
          // redirect: '/conversation',
        },
        {
          path: '/conversation',
          name: 'conversation',
          component: Conversation,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/collections',
      name: 'collections',
      component: Collections,
      meta: { requiresAuth: true },
    },
    {
      path: '/collection/:id/:questionId?',
      name: 'collection',
      component: Collection,
      meta: { requiresAuth: true },
    },
    {
      path: '/user',
      component: SideLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'settings',
          name: 'settings',
          component: Settings,
          meta: { requiresAuth: true },
        },
        {
          path: 'security',
          name: 'security',
          component: Security,
          meta: { requiresAuth: true },
        },
        {
          path: 'datasources',
          name: 'datasources',
          component: Datasources,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/passwordreset',
      name: 'password-reset',
      component: Login,
    },
    {
      path: '/register',
      name: 'register',
      component: Login,
    },
    {
      path: '/authcallback',
      name: 'authcallback',
      component: AuthCallback,
    },
    // pathMatch is the name of the param, e.g., going to /not/found yields
    // { params: { pathMatch: ['not', 'found'] }}
    // this is thanks to the last *, meaning repeated params and it is necessary if you
    // plan on directly navigating to the not-found route using its name
    { path: '/:pathMatch(.*)*', name: 'not-found', redirect: '/login' },
    // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
    { path: '/:pathMatch(.*)', name: 'bad-not-found', redirect: '/login' },
  ],
});

router.setStore = (vuexStore) => {
  store = vuexStore;
};

router.beforeEach(async (to, from, next) => {
  if (to.fullPath && to.query.redirect && to.path !== '/authcallback') {
    next(to.query.redirect);
    return;
  }
  if (store.state.bootstrapped && to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.user || !store.state.user.email) {
      next({ path: '/login', params: { nextUrl: to.fullPath } });
      return;
    }
    const { users } = to.meta;
    if (users && users.length && !users.includes(store.state.user.email)) {
      // email not authorised so redirect to home page
      next('/');
      return;
    }

    if (to.matched.some((record) => record.meta.feature && !store.state.features.includes(record.meta.feature))) {
      next({ path: '/' });
      return;
    }
  }

  next();
});

router.afterEach((to, from) => {
  if (from.name === 'dashboard' && to.name === 'conversation' && to.hash === '#dashboard') {
    to.meta.transition = 'slide-up';
  }
});

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  }
});

export default router;
