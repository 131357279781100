<template>
  <div class="w-full h-full flex flex-col overflow-hidden">
    <div class="flex-shrink-0 w-full flex justify-between px-5 h-15 items-center">
      <div class="text-17 flex justify-center items-center font-600 text-primary">
        <IconButton size="8" icon="chevron_left" class="mr-2 !py-0" @click="onBackClick" />
        <span v-if="editMode" class="text-18 sm:text-24">{{ $t('collections.edit_question') }}</span>
        <span v-else class="text-18 sm:text-24">{{ $t('collections.add_question') }}</span>
      </div>
      <div class="flex items-center">
        <PillButton :disabled="!saveEnabled" :loading="saving" icon="save" :text="$t('save')" primary @click="save" />
      </div>
    </div>
    <div class="flex-grow overflow-auto px-1">
      <div class="w-full p-5">
        <div class="flex pb-5 max-w-800 mx-auto w-full" :class="{ 'pointer-events-none': loading }">
          <div class="mr-2 md:mr-5 max-md:w-full w-250">
            <label class="block text-13 mb-1">{{ $t('data_source') }}</label>
            <v-select
              v-model="datasource"
              class="w-full"
              rounded
              elevated
              :placeholder="$t('datastore.select_data_source')"
              dense
              alternativeIndicator
              auto-select
              :clearable="false"
              :searchable="false"
              :options="getDataSources"
              :reduce="(option) => option.value"
              @option:selected="onDatasourceChanged"
            ></v-select>
          </div>
          <div class="max-md:w-full w-250">
            <label class="block text-13 mb-1">{{ $t('account') }}</label>
            <v-select
              v-model="account"
              :disabled="!datasource"
              class="w-full"
              rounded
              elevated
              dense
              auto-select
              alternativeIndicator
              :placeholder="$t('accounts_dropdown.select_account')"
              :clearable="false"
              :searchable="false"
              :options="getAccounts"
              :reduce="(option) => option.value"
              @update:modelValue="onAccountChanged"
            ></v-select>
          </div>
        </div>
        <div class="flex pb-5 max-w-800 mx-auto">
          <div class="w-full">
            <label class="block text-13 mb-1">{{ $t('collections.question') }}</label>
            <div class="w-full relative" v-outside-click="onSearchBlur">
              <div class="absolute bg-white top-9 w-full py-2 rounded-t-2xl z-2 border-gray-200 border-1 shadow-mini-card scroll-smooth" v-show="showSuggestions" ref="suggestions">
                <div class="max-h-[294px] overflow-auto" style="max-height: 294px">
                  <section class="px-1" v-if="suggestionResults.length">
                    <div class="my-2 px-2 font-500">{{ $t('you_may_ask') }}</div>
                    <ul>
                      <li
                        class="flex items-center hover:bg-purple-200 hover:text-purple-900 p-1 rounded-full cursor-default"
                        :class="[index === highlightedSuggestionIndex ? 'bg-primary text-white' : '']"
                        v-for="(suggestion, index) in suggestionResults"
                        :key="suggestion.query.replace('', '-')"
                        :data-scroll-key="`index-${index}`"
                        @click="onClickSuggestion(suggestion.query)"
                      >
                        <span class="ml-2" v-html="suggestion.html"></span>
                      </li>
                    </ul>
                  </section>
                </div>
              </div>
              <input
                v-model="phrase"
                type="text"
                class="transition-border z-0 rounded-full border-solid ease-in duration-75 shadow-md bg-white h-8 w-full px-4 outline-none text-14 placeholder-gray-400 relative"
                :class="{
                  'border-white border-2': !!phrase || focused,
                  'border-white bg-purple-100 border-2': !phrase && !focused,
                  'opacity-50': !account,
                }"
                :placeholder="$t('search_placeholder')"
                ref="input"
                :disabled="!account || loading || saving"
                @focus="onSearchFocus"
                @keyup="onKeyUpInput"
                tabindex="1"
              />
            </div>
          </div>
          <div class="pt-6 ml-4">
            <PillButton :disabled="!phrase || loading" :text="$t('preview')" primary @click="onPreviewClick" />
          </div>
        </div>
        <div v-if="analysisCardResult" class="flex max-w-800 mx-auto max-sm:flex-wrap">
          <div class="flex w-600 max-sm:w-full">
            <div class="mr-2 md:mr-5 w-1/2">
              <label class="block text-13 mb-1">{{ $t('date_range') }}</label>
              <v-select
                v-model="dateRange"
                :disabled="!datasource"
                class="w-full"
                rounded
                elevated
                dense
                alternativeIndicator
                :placeholder="$t('select')"
                :clearable="false"
                :searchable="false"
                :options="getDateRanges"
                :reduce="(option) => option.value"
                @option:selected="onDateRangeClick"
              ></v-select>
            </div>
            <div class="mr-2 md:mr-5 w-1/2">
              <label class="block text-13 mb-1">{{ $t('histogram') }}</label>
              <v-select
                v-model="histogram"
                :disabled="!datasource"
                class="w-full"
                rounded
                elevated
                dense
                alternativeIndicator
                :placeholder="$t('select')"
                :clearable="false"
                :searchable="false"
                :options="getHistogramItems"
                :reduce="(option) => option.value"
                @option:selected="onHistogramClick"
              ></v-select>
            </div>
            <div class="sm:mr-2 md:mr-5 w-1/2">
              <label class="block text-13 mb-1">{{ $t('auto_refresh.title') }}</label>
              <v-select
                v-model="interval"
                :disabled="!datasource"
                class="w-full"
                rounded
                elevated
                dense
                alternativeIndicator
                :placeholder="autoRefreshLabel"
                :clearable="false"
                :searchable="false"
                :options="autoRefreshPeriods"
                :reduce="(option) => option.value"
                @option:selected="onAutoRefreshSelect"
              ></v-select>
            </div>
          </div>
          <div class="flex">
            <PillButton v-if="editMode" elevated :text="$t('move')" icon="get_app" class="mt-[22px] mr-2 md:mr-5" @click="showMoveCardModal = true" />
            <PillButton v-if="editMode" elevated :text="$t('remove')" icon="delete" class="mt-[22px] ml-auto" @click="removeFromCollection" />
          </div>
        </div>

        <div v-if="analysisCardResult" class="w-full m-auto max-w-800 flex flex-col justify-center mt-3">
          <div class="w-full shadow-analysisCard rounded-26 mt-3 bg-off-white">
            <AnalysisCard
              :export-chart-type="displayType"
              :datasource-type="datasource"
              :result="{ ...analysisCardResult, title: phrase }"
              @export="onAnalysisCardExport"
              @displayTypeChange="onDisplayTypeChange"
            />
          </div>
        </div>

        <div v-if="loading" class="w-full mt-15 h-[280px] max-w-800 rounded-[16px] shadow-card mx-auto">
          <div class="flex-grow flex items-center justify-center h-full">
            <Spinner class="w-8" />
          </div>
        </div>

        <AnalysisCardExportModal
          v-if="selectedQuestion"
          :datasource-type="selectedQuestion.type"
          :export-chart-type="displayType"
          :result="analysisCardResult"
          :show-export-modal="showExportModal"
          :showCardText="showCardText"
          :showInsightsPanel="showInsightsPanel"
          @close="showExportModal = false"
        />

        <SelectCollectionCardModal
          v-if="showMoveCardModal"
          :selected-question="selectedQuestion"
          :collection-id="collectionId"
          :question-id="selectedQuestion.id"
          :title="$t('collections.move_card_to')"
          :query-data-result="selectedQuestion.result"
          @close="onSelectionCardModalClose"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { apiGetSuggestions, apiPostCollectionPreview, apiPostCollectionsQuery } from '@/helpers/api';
import SelectCollectionCardModal from '@/components/collection/SelectCollectionCardModal.vue';
import AnalysisCardExportModal from '@/components/conversation/AnalysisCardExportModal.vue';
import AnalysisCard from '@/components/conversation/AnalysisCard.vue';
import { AUTO_REFRESH_VALUES } from '@/constants';
import DeleteModal from '@/components/shared-modals/DeleteModal.vue';
import i18n from '@/plugins/i18n';
import { searchAndHighlight } from '@/helpers/search';

export default {
  name: 'CollectionCard',
  components: { AnalysisCard, AnalysisCardExportModal, SelectCollectionCardModal },
  data() {
    return {
      showExportModal: false,
      showCardText: false,
      showInsightsPanel: false,
      showMoveCardModal: false,
      interval: 0,
      displayType: null,
      dateRange: null,
      draftAutoRefreshPeriod: null,
      variables: null,
      loading: false,
      saving: false,
      histogram: null,
      datasource: null,
      datasourceId: null,
      account: null,
      question: null,
      tested: null,
      phrase: '',
      focused: false,
      dirty: false,
      highlightedSuggestionIndex: 0,
      analysisCardResult: null,
      suggestions: [],
    };
  },
  computed: {
    ...mapState(['data_sources', 'collections', 'activeDatasourceType', 'bootstrapped', 'availableDataSources', 'user', 'collectionCardResults']),
    ...mapGetters(['getConversationList']),
    modelId() {
      return this.data_sources?.[this.datasource]?.[this.datasourceId]?.accounts?.[this.account]?.properties?.MODEL_ID || null;
    },
    selectedQuestionCardResult() {
      return this.collectionCardResults?.[this.selectedQuestionId]?.result || null;
    },
    saveEnabled() {
      return this.dirty && this.analysisCardResult && !this.saving;
    },
    editMode() {
      return !!this.selectedQuestionId;
    },
    selectedQuestion() {
      const collection = this.collections.find((col) => col.id === this.collectionId) || {};
      const questions = collection?.questions || [];
      return questions.find((q) => q.id === this.selectedQuestionId) || {};
    },
    collectionId() {
      return this.$route.params.id;
    },
    selectedQuestionId() {
      if (this.$route?.params?.questionId && this.$route.params.questionId === 'new') {
        return null;
      }
      return this.$route?.params?.questionId || null;
    },
    showSuggestions() {
      return this.focused && this.suggestionResults.length > 0;
    },
    suggestionResults() {
      return searchAndHighlight(this.suggestions, this.phrase);
    },
    autoRefreshLabel() {
      const period = Object.keys(AUTO_REFRESH_VALUES).find((key) => AUTO_REFRESH_VALUES[key] === this.interval || 0);
      if (period && period !== '0_MIN') {
        return i18n.global.tm(`auto_refresh.periods.${period}`);
      }
      return this.$t('select');
    },
    getDataSources() {
      return Object.keys(this.data_sources).map((key) => ({
        label: this.availableDataSources[key].name,
        value: key,
      }));
    },
    getAccounts() {
      const accounts = [];
      if (this.datasource) {
        Object.keys(this.data_sources[this.datasource]).forEach((datasourceId) => {
          Object.values(this.data_sources[this.datasource][datasourceId]?.accounts || {})?.forEach((account) => {
            accounts.push({
              label: account.name,
              value: account.account_id,
              datasourceId: account.datasource_id,
              account,
            });
          });
        });
      }
      return accounts;
    },
    getDateRanges() {
      const dateRanges = i18n.global.tm('date_ranges');
      return Object.keys(dateRanges).map((key) => {
        return {
          label: dateRanges[key],
          value: key,
        };
      });
    },
    getHistogramItems() {
      const histogramValues = i18n.global.tm('histogram_values');
      return Object.keys(histogramValues).map((key) => {
        return {
          label: histogramValues[key],
          value: key,
        };
      });
    },
    autoRefreshPeriods() {
      const dateRanges = i18n.global.tm('auto_refresh.periods');
      return Object.keys(dateRanges).map((key) => {
        return {
          label: dateRanges[key],
          value: AUTO_REFRESH_VALUES[key],
        };
      });
    },
  },
  beforeUnmount() {
    this.SET_SELECTED_COLLECTION_QUESTION(null);
  },
  methods: {
    ...mapActions(['updateCollection', 'showToastMessage']),
    ...mapActions(['removeTenantQuestionFromCollection', 'showToastMessage', 'updateTenantQuestion', 'addTenantCollectionQuestion']),
    ...mapMutations(['SET_SELECTED_COLLECTION_QUESTION']),
    async onPreviewClick() {
      this.loading = true;
      this.analysisCardResult = null;
      const response = await apiPostCollectionPreview({ phrase: this.phrase, type: this.datasource, datasource_id: this.datasourceId, account_id: this.account, date_range: this.dateRange });

      if (response.status === 200) {
        if (response.data) {
          const { variables, responses } = response.data;

          this.variables = variables;

          if (Array.isArray(responses) && responses.length) {
            this.analysisCardResult = responses[0].card || {};

            if (!responses[0]?.card?.text && responses[0].text) {
              this.analysisCardResult.card = { text: responses[0].text };
            }
          }

          if (variables.date1 && variables.date1.date_range) {
            this.dateRange = variables.date1.date_range;
          }
          if (variables.histogram_bucket && variables.histogram_bucket.value) {
            this.histogram = variables.histogram_bucket.value;
          }
          this.loading = false;
        } else if (response.data?.error && response.data?.message) {
          this.loading = false;
          this.dateRange = null;
          this.histogram = null;
          this.showToastMessage({ title: response.data.message, type: 'error' });
        }
      } else {
        this.loading = false;
        this.dateRange = null;
        this.histogram = null;
        this.showToastMessage({ title: this.$t('collections.failed_to_preview_question'), type: 'error' });
      }
    },
    onAutoRefreshSelect() {
      this.dirty = true;
    },
    onDatasourceChanged() {
      this.datasourceId = '';
      this.account = '';
      this.fetchConversationSuggestions();
      this.dirty = true;
    },
    async fetchConversationSuggestions() {
      if (!this.datasource || !this.modelId) return;
      const response = await apiGetSuggestions(this.datasource, this.modelId);
      if (response.status === 200) {
        this.suggestions = response.data?.suggestions?.[this.user.language] || [];
      }
    },
    onAccountChanged(item) {
      item = this.getAccounts.find((i) => i.value === item);
      this.datasourceId = item.datasourceId;
      this.fetchConversationSuggestions();
      this.dirty = true;
    },
    onDateRangeClick() {
      this.dirty = true;
      this.onPreviewClick();
    },
    onHistogramClick() {
      this.dirty = true;
      this.onPreviewClick();
    },
    async save() {
      if (this.dateRange) {
        if (this.variables.date1 && this.variables.date1.date_range) {
          this.variables.date1.date_range = this.dateRange;
        }
      }
      if (this.histogram) {
        if (this.variables.histogram_bucket && this.variables.histogram_bucket.value) {
          this.variables.histogram_bucket.value = this.histogram;
        }
      }

      this.saving = true;
      let res;
      if (this.editMode) {
        res = await this.updateTenantQuestion({
          id: this.selectedQuestionId,
          phrase: this.phrase,
          collection_id: this.collectionId,
          type: this.datasource,
          datasource_id: this.datasourceId,
          account_id: this.account,
          interval: this.interval || 0,
          variables: this.variables,
        });

        if (res) {
          this.showToastMessage({ message: this.$t('collections.card_updated'), type: 'success' });
          this.$emit('save', { displayType: this.displayType, id: this.selectedQuestionId });
          this.onBackClick();
        } else {
          this.showToastMessage({ title: this.$t('collections.failed_to_update_card'), type: 'error' });
        }
      } else {
        res = await this.addTenantCollectionQuestion({
          phrase: this.phrase,
          collection_id: this.collectionId,
          type: this.datasource,
          datasource_id: this.datasourceId,
          account_id: this.account,
          interval: this.interval || 0,
          variables: this.variables,
        });

        if (res) {
          this.onBackClick();
          this.$emit('save', { displayType: this.displayType, id: res.id });
        } else {
          this.showToastMessage({ title: this.$t('collections.failed_to_add_card'), type: 'error' });
        }
      }
      this.saving = false;
    },
    onAnalysisCardExport({ showCardText, showInsightsPanel }) {
      this.showExportModal = true;
      this.showCardText = showCardText;
      this.showInsightsPanel = showInsightsPanel;
    },
    onDisplayTypeChange(displayType) {
      this.displayType = displayType;
      this.dirty = true;
    },
    onSelectionCardModalClose() {
      this.showMoveCardModal = false;
    },
    async removeFromCollection() {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('collections.remove_collection_question_question'),
        onConfirm: async () => {
          const res = await this.removeTenantQuestionFromCollection({ collection_id: this.collectionId, id: this.selectedQuestion.id });
          if (res) this.onBackClick();
        },
      });
    },
    onClickSuggestion(suggestion) {
      this.phrase = suggestion;
      this.focused = false;
    },
    getIfListItemVisibleOnScrollableArea() {
      const { suggestions } = this.$refs;
      const { highlightedSuggestionIndex } = this;
      const item = suggestions.querySelector(`[data-scroll-key="index-${highlightedSuggestionIndex}"]`);
      if (!item) {
        return null;
      }
      const rectEl = item.getBoundingClientRect();
      const rectContainer = suggestions.getBoundingClientRect();
      // 64 is the height of the area stays under the input div, the invisible part at the bottom of the suggestions panel
      if (rectEl.y >= rectContainer.bottom - 64 || rectEl.y <= rectContainer.top) {
        return item; // item is NOT visible on scrollable area
      }
      return null; // item is visible on scrollable area
    },
    onSearchBlur() {
      this.focused = false;
      this.highlightedSuggestionIndex = null;
    },
    onSearchFocus() {
      this.focused = true;
      this.$nextTick(() => {
        const { suggestions } = this.$refs;
        if (suggestions) {
          suggestions.scrollTop = 0;
        }
      });
    },
    onKeyUpInput(e) {
      if (e.code === 'ArrowUp') {
        this.highlightedSuggestionIndex = (this.highlightedSuggestionIndex !== null ? this.highlightedSuggestionIndex : 1) - 1;
        if (this.highlightedSuggestionIndex < 0) {
          this.highlightedSuggestionIndex = this.suggestionResults.length - 1;
        }
        const item = this.getIfListItemVisibleOnScrollableArea();
        item &&
          item.scrollIntoView({
            alignToTop: true,
            block: 'end',
            behavior: 'smooth',
          });
        e.stopPropagation();
        e.preventDefault();
      } else if (e.code === 'ArrowDown') {
        this.highlightedSuggestionIndex = this.highlightedSuggestionIndex !== null ? this.highlightedSuggestionIndex + 1 : 0;
        this.highlightedSuggestionIndex %= this.suggestionResults.length;
        const item = this.getIfListItemVisibleOnScrollableArea();
        item &&
          item.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        e.stopPropagation();
        e.preventDefault();
      } else if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        if (this.highlightedSuggestionIndex in this.suggestionResults) {
          this.phrase = this.suggestionResults[this.highlightedSuggestionIndex];
          this.dirty = true;
        }
        this.highlightedSuggestionIndex = null;
        this.focused = false;
      } else {
        this.highlightedSuggestionIndex = null;
        this.dirty = true;
        this.dateRange = null;
        this.histogram = null;
        this.analysisCardResult = null;
      }
    },
    onBackClick() {
      this.$router.push({ name: 'collection', params: { id: this.collectionId } });
    },
  },
  watch: {
    bootstrapped: {
      async handler() {
        this.loading = true;
        if (this.bootstrapped) {
          if (this.selectedQuestionId) {
            const collection = this.collections.find((col) => col.id === this.collectionId);
            const question = collection.questions.find((q) => q.id === this.selectedQuestionId);
            const layoutItem = collection.layout.find((i) => i.i === this.selectedQuestionId);
            if (layoutItem?.c) {
              this.displayType = layoutItem.c;
            }

            this.datasource = question.type;
            this.datasourceId = question.datasource_id;
            this.account = question.account_id;
            this.interval = question.interval;
            this.phrase = question.phrase;
            this.variables = question.variables || {};
            if (this.variables?.date1 && this.variables.date1.date_range) {
              this.dateRange = this.variables.date1.date_range;
            }
            if (this.variables?.histogram_bucket && this.variables.histogram_bucket.value) {
              this.histogram = this.variables.histogram_bucket.value;
            }
            if (!this.selectedQuestionCardResult) {
              const result = await apiPostCollectionsQuery({
                type: question.type,
                datasource_id: question.datasource_id,
                account_id: question.account_id,
                collection_id: this.collectionId,
                question_id: question.question_id,
                id: question.id,
              });

              if (result.status === 200) {
                const [response] = result.data.responses;
                this.SET_SELECTED_COLLECTION_QUESTION({
                  question: result.data.question,
                  card: response.card,
                });

                this.analysisCardResult = response.card;
                this.loading = false;
                this.fetchConversationSuggestions();
              } else {
                this.showToastMessage({ title: this.$t('collections.failed_to_preview_question'), type: 'error' });
                this.onBackClick();
              }
            } else {
              this.analysisCardResult = this.selectedQuestionCardResult;
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.vue-grid-item.vue-grid-placeholder {
  background: #f7f0ff;
  opacity: 1;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border: solid 2px rgba(83, 55, 232, 0.2);
  border-radius: 10px;
}
.vue-grid-item.vue-draggable-dragging {
  border: solid 2px #5337e8;
  border-radius: 10px;
}
.vue-grid-item .vue-resizable-handle {
  padding: 0 4px 4px 0;
}

.default-layout[data-compact='true'] {
  .sidebar {
    display: none;
  }
  .main-content {
    padding: 0;
  }
}
</style>
