export function searchAndHighlight(array, searchText) {
  const searchWords = searchText
    .toLowerCase()
    .split(' ')
    .map((w) => w.trim())
    .filter((w) => w);
  return array
    .map((item) => {
      let isMatch = true;
      let modifiedItem = item;

      searchWords.forEach((searchWord) => {
        // Using regex to match a word that doesn't include <mark> and </mark> tags
        const regex = new RegExp(`(?!<mark>)(${searchWord})(?!</mark>)`, 'i');

        if (!regex.test(modifiedItem)) {
          isMatch = false;
        } else {
          // Wrap the matched part with <mark> tag
          modifiedItem = modifiedItem.replace(regex, '<mark>$1</mark>');
        }
      });

      // If a match is found, return the result
      if (isMatch) {
        return {
          query: item,
          html: modifiedItem,
        };
      }
      return null;
    })
    .filter((item) => item !== null); // Filter out null values
}
export default { searchAndHighlight };
